import React from 'react'
import Navbar from '../Navbar'
import { NavLink } from "react-router-dom";
import Footer from '../Footer';
import BlogBg from "../../img/blog.png"


function Blog() {
    return (
        <>
            <Navbar />

            <div className='pt-16'>
                <div
                    style={{
                        backgroundImage: `url(${BlogBg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}
                    className="md:bg-cover bg-contain lg:px-14 px-6 md:pb-24 pb-10 mx-auto  flex flex-col lg:justify-center lg:space-x-14 relative"
                >
                    <div className="place-self-center text-center md:pt-32 md:pb-10 pt-8">
                        <h1 className="md:text-5xl text-2xl text-[#191A15] font-bold">
                            Blogs

                        </h1>

                        <p className="md:mt-5 mt-2 md:text-base text-[#999A93] text-sm">
                            Get News with AfriPay
                        </p>
                    </div>
                </div>
            </div>

            {/* <-- Blog --> */}
            <section className="bg-[#FFFFFF] body-font font-poppins py-10">
                <div className='lg:px-16 mx-auto px-8 pt-3'>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-8">

                    <div className=''>
                            <div className='relative'>
                            <img src="/images/blog9-1.jpeg" alt="blog" className='rounded-[10px]'/>
                                {/* <div className=' absolute top-4 left-3'>
                                <span className='bg-[#14B266] text-white text-[10px] py-[10px] px-[12px] rounded-[33px]'>Published on 25 July 2024</span>
                                </div> */}
                                
                            </div>
                            <div className='mt-2'>
                                <h3 className='md:text-[20px] text-[18px] text-[#3A3939] font-medium'>Saving Tactics to Afford Your International Education Dreams with AfriPay</h3>
                                <p className='md:text-[12px] text-[10px] text-[#6C6A6A] mt-1'>Dreaming of studying abroad? It’s not just about gaining a prestigious degree; it's about experiencing new cultures, forging lifelong friendships, and opening doors to global...</p>
                            </div>
                            <NavLink to="/saving-tactics-to-afford-your-international-education-dreams-with-afripay">
                            <div className='mt-2'>
                                <button className='w-full border border-[#14B266] text-[#14B266] p-[10px] rounded-[10px]'>Learn More</button>
                            </div>
                            </NavLink>       
                        </div>

                        <div className=''>
                            <div className='relative'>
                            <img src="/images/blog8.jpg" alt="blog" className='rounded-[10px]'/>
                                {/* <div className=' absolute top-4 left-3'>
                                <span className='bg-[#14B266] text-white text-[10px] py-[10px] px-[12px] rounded-[33px]'>Published on 25 July 2024</span>
                                </div> */}
                                
                            </div>
                            <div className='mt-2'>
                                <h3 className='md:text-[20px] text-[18px] text-[#3A3939] font-medium'>How Can I Study Abroad Without the Finances?</h3>
                                <p className='md:text-[12px] text-[10px] text-[#6C6A6A] mt-1'>Dreaming of studying abroad but worried about the financial hurdles? You're not alone, and the good news is, there are options available to...</p>
                            </div>
                            <NavLink to="/how-can-i-study-abroad-without-the-finances">
                            <div className='mt-2'>
                                <button className='w-full border border-[#14B266] text-[#14B266] p-[10px] rounded-[10px]'>Learn More</button>
                            </div>
                            </NavLink>       
                        </div>

                    <div className=''>
                            <div className='relative'>
                            <img src="/images/blog7.jpg" alt="blog" className='rounded-[10px]'/>
                                {/* <div className=' absolute top-4 left-3'>
                                <span className='bg-[#14B266] text-white text-[10px] py-[10px] px-[12px] rounded-[33px]'>Published on 25 July 2024</span>
                                </div> */}
                                
                            </div>
                            <div className='mt-2'>
                                <h3 className='md:text-[20px] text-[18px] text-[#3A3939] font-medium'>Has Your Password Leaked Before? This Is How It Happened</h3>
                                <p className='md:text-[12px] text-[10px] text-[#6C6A6A] mt-1'>In today's digital age, passwords are the keys to our online kingdoms. Yet, despite our best efforts to keep them secure, password leaks still happen all too often...</p>
                            </div>
                            <NavLink to="/has-your-password-leaked-before">
                            <div className='mt-2'>
                                <button className='w-full border border-[#14B266] text-[#14B266] p-[10px] rounded-[10px]'>Learn More</button>
                            </div>
                            </NavLink>       
                        </div>

                    <div className=''>
                            <div className='relative'>
                            <img src="/images/blog6.jpg" alt="blog" className='rounded-[10px]'/>
                                {/* <div className=' absolute top-4 left-3'>
                                <span className='bg-[#14B266] text-white text-[10px] py-[10px] px-[12px] rounded-[33px]'>Published on 25 July 2024</span>
                                </div> */}
                                
                            </div>
                            <div className='mt-2'>
                                <h3 className='md:text-[20px] text-[18px] text-[#3A3939] font-medium'>Top 10 Tips for Budgeting Your Study Abroad Expenses</h3>
                                <p className='md:text-[12px] text-[10px] text-[#6C6A6A] mt-1'>Studying abroad is a dream for many students, but managing finances in a foreign country can be challenging. Proper budgeting is essential to ensure that you can make the most of your...</p>
                            </div>
                            <NavLink to="/top-10-tips-for-budgeting-your-study-abroad-expenses">
                            <div className='mt-2'>
                                <button className='w-full border border-[#14B266] text-[#14B266] p-[10px] rounded-[10px]'>Learn More</button>
                            </div>
                            </NavLink>       
                        </div>


                    <div className=''>
                            <div className='relative'>
                            <img src="/images/blog5.jpg" alt="blog" className='rounded-[10px]'/>
                                {/* <div className=' absolute top-4 left-3'>
                                <span className='bg-[#14B266] text-white text-[10px] py-[10px] px-[12px] rounded-[33px]'>Published on 25 July 2024</span>
                                </div> */}
                                
                            </div>
                            <div className='mt-2'>
                                <h3 className='md:text-[20px] text-[18px] text-[#3A3939] font-medium'>Understanding Credit Reports and Credit Scores with AfriPay</h3>
                                <p className='md:text-[12px] text-[10px] text-[#6C6A6A] mt-1'>In many places worldwide, being creditworthy is a significant asset. Suppose you’ve ever wondered how to assess your financial standing and gain...</p>
                            </div>
                            <NavLink to="/understanding-credit-reports-and-credits-scores-with-afripay">
                            <div className='mt-2'>
                                <button className='w-full border border-[#14B266] text-[#14B266] p-[10px] rounded-[10px]'>Learn More</button>
                            </div>
                            </NavLink>
                            
                        </div>

                    <div className=''>
                            <div className='relative'>
                            <img src="/images/blog4.jpg" alt="blog" className='rounded-[10px]'/>
                                {/* <div className=' absolute top-4 left-3'>
                                <span className='bg-[#14B266] text-white text-[10px] py-[10px] px-[12px] rounded-[33px]'>Published on 25 July 2024</span>
                                </div> */}
                                
                            </div>
                            <div className='mt-2'>
                                <h3 className='md:text-[20px] text-[18px] text-[#3A3939] font-medium'>Safety Tips for Transacting Online with AfriPay</h3>
                                <p className='md:text-[12px] text-[10px] text-[#6C6A6A] mt-1'>In today's digital age, online transactions have become an integral part of our daily lives. Whether you're paying tuition fees, booking flights, or applying for study loans, ensuring your online transactions are...</p>
                            </div>
                            <NavLink to="/safety-tips-for-transacting-online-with-afripay">
                            <div className='mt-2'>
                                <button className='w-full border border-[#14B266] text-[#14B266] p-[10px] rounded-[10px]'>Learn More</button>
                            </div>
                            </NavLink>
                            
                        </div>

                    <div className=''>
                            <div className='relative'>
                            <img src="/images/blog3.jpg" alt="blog" className='rounded-[10px]'/>
                                {/* <div className=' absolute top-4 left-3'>
                                <span className='bg-[#14B266] text-white text-[10px] py-[10px] px-[12px] rounded-[33px]'>Published on 10 July 2024</span>
                                </div> */}
                                
                            </div>
                            <div className='mt-2'>
                                <h3 className='md:text-[20px] text-[18px] text-[#3A3939] font-medium'>Boost Your Education with AfriPay: Why STEM Courses Are Your Best Choice</h3>
                                <p className='md:text-[12px] text-[10px] text-[#6C6A6A] mt-1'>Many dream of higher education, but finances often pose a hurdle. That's where AfriPay steps in, revolutionizing educational loans for international students ...</p>
                            </div>
                            <NavLink to="/boost-your-education-with-afripay">
                            <div className='mt-2'>
                                <button className='w-full border border-[#14B266] text-[#14B266] p-[10px] rounded-[10px]'>Learn More</button>
                            </div>
                            </NavLink>
                            
                        </div>

                        <div className=''>
                            <div className='relative'>
                            <img src="/images/blog2.jpg" alt="blog" className='rounded-[10px]'/>
                                {/* <div className=' absolute top-4 left-3'>
                                <span className='bg-[#14B266] text-white text-[10px] py-[10px] px-[12px] rounded-[33px]'>Published on 8 July 2024</span>
                                </div> */}
                                
                            </div>
                            <div className='mt-2'>
                                <h3 className='md:text-[20px] text-[18px] text-[#3A3939] font-medium'>How to Improve Your Credit Score: A Comprehensive Guide for Africans</h3>
                                <p className='md:text-[12px] text-[10px] text-[#6C6A6A] mt-1'>A credit score is a numerical representation of an individual’s creditworthiness. It is calculated based on an individual’s credit history, which includes ...</p>
                            </div>
                            <NavLink to="/how-to-improve-your-credit-score">
                            <div className='mt-2'>
                                <button className='w-full border border-[#14B266] text-[#14B266] p-[10px] rounded-[10px]'>Learn More</button>
                            </div>
                            </NavLink>
                            
                        </div>
                        
                    </div>
                </div>
            </section>

            {/* <-- Footer --> */}
            <Footer />
        </>
    )
}

export default Blog