import React, { useState } from 'react'
import Sidebar from "../../Sidebar/Sidebar";
import UserNavbar from "../../Navbars/UserNavbar";
import HeaderTitle from '../../ReuseableComponent/HeaderTitle';
import { AdminApis } from '../../../apis/adminApi';
import { AxiosResponse } from 'axios';
import { DashboardApi } from '../../../apis/DashboardApi';
import { PaymentApis } from '../../../apis/paymentApis';

function Transactions() {

    const [transactionList, setTransactionList] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState('');


    React.useEffect(() => {
        PaymentApis.getTransactionHistory('').then(
            (response: AxiosResponse<any>) => {
                if (response?.data) {
                    setTransactionList(response?.data)
                    console?.log(response?.data)
                }
            }
        ).catch(function (error) {
            // handle error

        })


    }, []);

    console?.log(transactionList)



    const paginator = React.useCallback(
        (value: any) => {
            //   setLoader(true);
            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }

            PaymentApis.getTransactionHistory(value2).then(
                (response: AxiosResponse<any>) => {
                    if (response?.data) {
                        setTransactionList(response?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [transactionList, searchText]);




    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64">
                <UserNavbar />
                <div className='lg:mt-5 lg:px-10 px-6 bg-[#Ffffff] min-h-screen'>
                    <HeaderTitle title="Transactions" />

                    <div className='mt-20'>
                        {/* <div className='md:ml-[30px] md:mr-[100px]'>
                            <div className='flex flex-row justify-between overflow-auto'>
                                <div className="text-[18px] text-[#00B07B] font-semibold ">Date </div>
                                <div className="text-[18px] text-[#00B07B] font-semibold ">Description</div>
                                <div className="text-[18px] text-[#00B07B] font-semibold ">Amount</div>
                                <div className="text-[18px] text-[#00B07B] font-semibold ">Category</div>
                                <div className="text-[18px] text-[#00B07B] font-semibold ">Source </div>
                            </div>
                            <div className='flex flex-row justify-between overflow-auto my-2 border border-[#DADADA] rounded-[10px] py-[23px] pl-[20px] pr-[53px]'>
                                <div className=" ">11-02-2023 </div>
                                <div className=" ">Tuition Fees</div>
                                <div className="">$230</div>
                                <div className=" ">Deposit</div>
                                <div className=" ">Debit Card </div>
                            </div>
                        </div> */}


                        {/* <div className="relative overflow-x-auto  sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <thead className="text-[18px] text-[#00B07B] font-semibold ">
                                    <tr>

                                        <th scope="col" className="px-6 py-3">
                                            Payment For
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Amount
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Country From
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Payment Status
                                        </th>

                                        <th scope="col" className="px-6 py-3">
                                            Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        transactionList?.data?.map(
                                            (datas: any, index: any) => (
                                                <tr className="   border border-[#DADADA] rounded-[10px] mb-2">
                                                    <td className="px-6 py-4">
                                                        {datas?.payment_for}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {(datas?.sch_currency == 'EUR' ? "€" : '$') + ' ' + datas?.school_amount}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.country_from}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.payment_status}
                                                    </td>

                                                    <th scope="row" className="px-6 py-4 font-medium text-[#979797]  ">
                                                        {(datas?.created_at).slice(0, 10)}
                                                    </th>
                                                </tr>
                                            )

                                        )

                                    }

                                </tbody>
                            </table>
                        </div> */}

                        {/* <div className='m-4'>
                        {
                            transactionList?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                (datas: any, index: any) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-green-500 text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }

                    </div> */}



                        <div className="relative overflow-x-auto">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Amount
                                        </th>
                                        <th scope="col" className="px-6 py-3"></th>
                                        <th scope="col" className="px-6 py-3">Transaction Type</th>

                                        <th scope="col" className="px-6 py-3">
                                            Transaction Status
                                        </th>
                                    </tr>
                                </thead>


                                <tbody className=''>
                                    {
                                        transactionList?.data?.map(
                                            (datas: any, index: any) => (
                                                <tr className="bg-white border-b ">

                                                    <td className="px-6 py-4 flex justify-start gap-3">
                                                        <span>{index + 1}</span>
                                                        <span className="font-[600]">{(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((datas?.amount_paid) ? (datas?.amount_paid) : 0.0))} </span>
                                                    </td>
                                                    <td className="px-6 py-4"></td>
                                                    <td className="px-6 py-4">

                                                        {datas?.tnx_naration == 'pay for products' ? <p><b style={{ fontWeight: "400", fontSize: '15px', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>⬇ {datas?.tnx_naration}</b></p> : ''}
                                                        {datas?.tnx_naration == 'topup' ? <p><b style={{ fontWeight: "400", fontSize: '15px', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}> ⬆ {datas?.tnx_naration}</b></p> : ''}


                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {datas?.transaction_status == 'pending' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.transaction_status}</b></p> : ''}
                                                        {datas?.transaction_status == 'successful' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.transaction_status}</b></p> : ''}
                                                        {datas?.transaction_status == 'delievered' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.transaction_status}</b></p> : ''}
                                                        {datas?.transaction_status == 'rejected' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#F5BFBF', color: '#E03130', borderRadius: '10px', padding: '2px 10px' }}>{datas?.transaction_status}</b></p> : ''}

                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }

                                </tbody>


                            </table>
                        </div>

                        {transactionList?.data?.length < 1 ?
                            <div className="d-flex justify-content-center mt-0 py-3" style={{ backgroundColor: '#F4F5FA' }}>
                                <p className="mt-2" style={{ color: '#989DA3', fontWeight: '500' }}>No Record Found!</p>
                            </div>
                            : ''
                        }



                        <div className='m-4 flex justify-end'>
                            {
                                transactionList?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                                    (datas: any, index: any) => (
                                        <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-[#14B266] text-white')}>
                                            {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                        </button>
                                    )
                                )
                            }

                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Transactions