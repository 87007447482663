import './App.css';
import { Routes, Route } from "react-router-dom";
//  import Navbar from './Components/Navbar';
// import Nav from './Components/Nav';
import Login from './Components/Pages/Auth/Login';
import Register from './Components/Pages/Auth/Register';
import ForgotPword from './Components/Pages/Auth/ForgotPword';
import EmailVerify from './Components/Pages/Auth/EmailVerify';
import EmailVerifyComplete from './Components/Pages/Auth/EmailVerifyComplete';
import OTPVerify from './Components/Pages/Auth/OTPVerify';
import ResetPassword from './Components/Pages/Auth/ResetPassword';
import PwordResetSucces from './Components/Pages/Auth/PwordResetSucces';
import Dashboard from './Components/Screens/User/Dashboard';
import PaymentList from './Components/Screens/User/PaymentLists';
import Profile from './Components/Screens/User/Profile';
import Transactions from './Components/Screens/User/Transactions';
import SchoolInfo from './Components/Screens/User/SchoolInfo';
import PaymentInfo from './Components/Screens/User/PaymentInfo';
import StudentInfo from './Components/Screens/User/StudentInfo';
import VerifyInfo from './Components/Screens/User/VerifyInfo';
import VerifyWalletPayment from './Components/Screens/User/verifyWalletPayment';
import MakePayment from './Components/Screens/User/MakePayment';
import ProofOfFunds from './Components/Screens/User/ProofOfFunds';
import DownloadForms from './Components/Screens/User/DownloadForms';
import UploadForms from './Components/Screens/User/UploadForms';
import KYC from './Components/Screens/User/KYC';
import Home from './Components/Pages/Home';
import AdminDashboard from './Components/Screens/Admin/AdminDashboard';
import Students from './Components/Screens/Admin/Students';
import Surveys from './Components/Screens/Admin/SurveyList';
import AdminProofOfFunds from './Components/Screens/Admin/ProofOfFunds';
import TuitionPaymentList from './Components/Screens/Admin/TuitionPaymentList';
import StudentDetails from './Components/Screens/Admin/StudentDetails';
import Schools from './Components/Screens/Admin/Schools';
import AddSchool from './Components/Screens/Admin/AddSchool';
import SchoolList from './Components/Screens/Admin/SchoolList';
import Loans from './Components/Screens/Admin/Loans';
import Product from './Components/Pages/Product';
import FAQ from './Components/FAQ';
import ContactUs from './Components/Pages/ContactUs';
import AfripayShop from './Components/Screens/User/AfripayShop';
import TravelStart from './Components/Screens/User/TravelStart';
import UserLoans from './Components/Screens/User/UserLoans';
import Savings from './Components/Screens/User/Savings';
import NewDashboard from './Components/Screens/User/NewDashboard';
import NewHome from './Components/Pages/NewHome';
import LoansPage from './Components/Pages/LoansPage';
import SurveyForm from './Components/Pages/SurveyFoem';
import SavingsPage from './Components/Pages/SavingsPage';
import TuitionPaymentPage from './Components/Pages/TuitionPaymentPage';
import AfripayShopPage from './Components/Pages/AfripayShopPage';
import TermsOfUse from './Components/Pages/TermsOfUse';
import StudentLoan from './Components/Pages/StudentLoan';
import Swap from './Components/Screens/User/Swap';
import StudentLoanList from './Components/Screens/Admin/StudentLoanList';
import StudentLoanDetails from './Components/Screens/Admin/StudentLoanDetails';
import ComingSoonPage from './Components/Pages/ComingSoonPage';
import About from './Components/Pages/About';
import GoldLoanPlan from './Components/Screens/User/GoldLoanPlan';
import PremiumLoanPlan from './Components/Screens/User/PremiumLoanPlan';
import LoanSilverEligibility from './Components/Screens/User/LoanSilverEligibility';
import LoanGoldEligibility from './Components/Screens/User/LoanGoldEligibility';
import LoanGoldCongrat from './Components/Screens/User/LoanGoldCongrat';
import LoanGoldForm from './Components/Screens/User/LoanGoldForm';
import LoanPremiumEligibility from './Components/Screens/User/LoanPremiumEligibility';
import LoanPremiumCongrat from './Components/Screens/User/LoanPremiumCongrat';
import PremiumLoanPlanNot from './Components/Screens/User/PremiumLoanPlanNot';
import PremiumLoanForm from './Components/Screens/User/PremiumLoanForm';
import LoanPremiumSuccess from './Components/Screens/User/LoanPremiumSuccess';
import LoanCreditDirect from './Components/Screens/User/LoanCreditDirect';
import VerifyCreditPayment from './Components/Screens/User/VerifyCreditPayment';
import VerifyCreditPremiumPayment from './Components/Screens/User/VerifyCreditPremiumPayment';
import VerifyCreditPremiumLoanPayment from './Components/Screens/User/VerifyCreditPremiumLoanPayment';
import SchoolFormList from './Components/Screens/Admin/SchoolFormList';
import SchoolFormDetails from './Components/Screens/Admin/SchoolFormDetails';
import Blog from './Components/Pages/Blog';
import CreditScore from './Components/Pages/BlogDetails/CreditScore';
import STEMCourses from './Components/Pages/BlogDetails/STEMCourses';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy';
import SafetyTips from './Components/Pages/BlogDetails/SafetyTips';
import CreditReport from './Components/Pages/BlogDetails/CreditReport';
import TipsForBudgeting from './Components/Pages/BlogDetails/TipsForBudgeting';
import PasswordLeaked from './Components/Pages/BlogDetails/PasswordLeaked';
import StudyAbroad from './Components/Pages/BlogDetails/StudyAbroad';
import SavingTactics from './Components/Pages/BlogDetails/SavingTactics';



function App() {

  caches.keys().then((names) => {
    Promise.all(names.map(name => caches.delete(name))).then(() => {
      // Perform a hard reload
      //  window.location.reload();
    });
  });

  return (
    <div>
      {/* <Navbar/> */}
      <Routes>
      {/* <Route path='*' element={<Login />}/> */}
           <Route  path="/sign-in" element={<Login/>}/>
           {/* <Route  path="/" element={<ComingSoonPage/>}/> */}
           <Route  path="/" element={<NewHome/>}/>
           <Route  path="/terms-of-use" element={<TermsOfUse/>}/>
           <Route  path="/privacy-policy" element={<PrivacyPolicy/>}/>
           <Route  path="/product" element={<Product/>}/>
           <Route  path="/student-loan" element={<StudentLoan/>}/>
           <Route  path="/swap" element={<Swap/>}/>
           <Route  path="/product" element={<Product/>}/>
           <Route  path="/about-us" element={<About/>}/>
           <Route  path="/faq" element={<FAQ/>}/>
           <Route  path="/loan" element={<LoansPage/>}/>
           <Route  path="/edupremium-registration-form" element={<SurveyForm/>}/>
           <Route  path="/savings-with-afripay" element={<SavingsPage/>}/>
           <Route  path="/tuition-payment" element={<TuitionPaymentPage/>}/>
           <Route  path="/afripayshop" element={<AfripayShopPage/>}/>
           <Route  path="/contact-us" element={<ContactUs/>}/>
           <Route  path="/sign-up" element={<Register/>}/>
           <Route  path="/forgot-password" element={<ForgotPword/>}/> 
           <Route  path="/email-verify" element={<EmailVerify/>}/>
           <Route  path="/email-verify/:email" element={<EmailVerifyComplete/>}/>
           <Route  path="/otp-verify" element={<OTPVerify/>}/>
           <Route  path="/reset-password" element={<ResetPassword/>}/>
           <Route  path="/reset-success" element={<PwordResetSucces/>}/>
           <Route path="/dashboard" element={<Dashboard/>} />
           <Route path="/payments" element={<PaymentList/>} />
           <Route path="/profile" element={<Profile/>} />
           <Route path="/transactions" element={<Transactions/>} />
           <Route path="/school-information" element={<SchoolInfo/>} />
           <Route path="/payment-information" element={<PaymentInfo/>} />
           <Route path="/student-information" element={<StudentInfo/>} />
           <Route path="/verify-information" element={<VerifyInfo/>} />
           <Route path="/makepayment" element={<MakePayment/>} />
           <Route path="/proof-of-funds" element={<ProofOfFunds/>} />
           <Route path="/download-forms" element={<DownloadForms/>} />
           <Route path="/upload-forms" element={<UploadForms/>} />
           <Route path="/kyc" element={<KYC/>} />
           <Route path="/loans" element={<UserLoans/>} />
           <Route path="/loans-silver-eligibility" element={<LoanSilverEligibility/>} />
           <Route path="/loans-gold-eligibility" element={<LoanGoldEligibility/>} />
           <Route path="/loans-premium-eligibility" element={<LoanPremiumEligibility/>} />
           <Route path="/loans-gold-success" element={<LoanGoldCongrat/>} />
           <Route path="/loans-premium-access" element={<LoanPremiumCongrat/>} />
           <Route path="/loans-premium-success" element={<LoanPremiumSuccess/>} />
           <Route path="/loans-gold-form" element={<LoanGoldForm/>} />
           <Route path="/loans-gold-plan" element={<GoldLoanPlan/>} />
           <Route path="/loans-premium-plan" element={<PremiumLoanPlan/>} />
           <Route path="/loans-premium-plan-form" element={<PremiumLoanForm/>} />
           <Route path="/loans-premium-plan-direct" element={<PremiumLoanPlanNot/>} />
           <Route path="/loans-credit" element={<LoanCreditDirect/>} />
           <Route  path="/verify-credit-payment" element={<VerifyCreditPayment/>}/>
           <Route  path="/verify-credit-premium-payment" element={<VerifyCreditPremiumPayment/>}/>
           <Route  path="/verify-credit-premium-loan-payment" element={<VerifyCreditPremiumLoanPayment/>}/>

           <Route path="/blog" element={<Blog/>} />
           <Route path="/how-to-improve-your-credit-score" element={<CreditScore/>} /> 
           <Route path="/boost-your-education-with-afripay" element={<STEMCourses/>} />
           <Route path="/safety-tips-for-transacting-online-with-afripay" element={<SafetyTips/>} />
           <Route path="/understanding-credit-reports-and-credits-scores-with-afripay" element={<CreditReport/>} />
           <Route path="/top-10-tips-for-budgeting-your-study-abroad-expenses" element={<TipsForBudgeting/>} />
           <Route path="/has-your-password-leaked-before" element={<PasswordLeaked/>} /> Saving Tactics to Afford Your International Education Dreams with AfriPay
           <Route path="/how-can-i-study-abroad-without-the-finances" element={<StudyAbroad/>} />
           <Route path="/saving-tactics-to-afford-your-international-education-dreams-with-afripay" element={<SavingTactics/>} />


           <Route path="/savings" element={<Savings/>} />
           <Route path="/afripay-shop" element={<AfripayShop/>} />
           <Route path="/travel-with-afripay" element={<TravelStart/>} />
           {/* <Route path="/travelstart" element={<TravelStart/>} /> */}
           <Route path="/new-dashboard" element={<NewDashboard/>} />

           <Route path="/admin-dashboard" element={<AdminDashboard/>} />
           <Route path="/list-of-students" element={<Students/>} />
           <Route path="/edupremium-form-list" element={<Surveys/>} />
           <Route path="/list-of-students-loan" element={<StudentLoanList/>} />
           <Route path="/list-of-school-form" element={<SchoolFormList/>} />
           <Route path="/list-of-pof" element={<AdminProofOfFunds/>} />
           <Route path="/list-of-tuition-payment" element={<TuitionPaymentList/>} />
           <Route  path="/verify-wallet-payment" element={<VerifyWalletPayment/>}/>
           <Route path="/student-details/:id" element={<StudentDetails/>} />
           <Route path="/student-loan-details/:user_id" element={<StudentLoanDetails/>} />
           <Route path="/school-form-details/:user_id" element={<SchoolFormDetails/>} />
           <Route path="/schools" element={<Schools/>} />
           <Route path="/add-school" element={<AddSchool/>} />
           <Route path="/list-of-schools" element={<SchoolList/>} />
           <Route path="/list-of-loans" element={<Loans/>} />

      </Routes>
      {/* <Nav/> */}
    </div>
  );
}

export default App;
